import * as React from "react"
import logo from '../images/romakidslogo.jpeg'
import ScrollPage from '../components/ScrollPage'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Avatar from '../components/Avatar'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ChildFriendlyOutlinedIcon from '@material-ui/icons/ChildFriendlyOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import BeachAccessOutlinedIcon from '@material-ui/icons/BeachAccessOutlined';
import LaptopChromebookOutlinedIcon from '@material-ui/icons/LaptopChromebookOutlined';
import DeckOutlinedIcon from '@material-ui/icons/DeckOutlined';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import testimonio1 from '../images/testimonio1.png'
import testimonio2 from '../images/testimonio2.png'
import escuela from '../images/escuela.jpeg'
import { navigate } from 'gatsby'
import Rating from '../components/Rating'
import '../styles/landingpage.css'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const whatsappsend = () => {
    navigate("https://wa.me/525517313307?text=Buen día necesito informes sobre ...")
}

const callphone = () => {
    navigate("tel:5552074043")
}

const llamar = <Button onClick={() => { callphone() }} variant="outlined" startIcon={<PhoneInTalkIcon />}>
    LLamar 5552074043
</Button>

const child = <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2} className="gridlanding" justifyContent={'center'}>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <br />
            <h1>
                Nuestros Servicios
            </h1>
            <hr />
            <h2>
                Excelente Nivel Educativo
            </h2>
            <h2>
                Educación Personalizada
            </h2>
        </Grid>
        <Grid item md={3} sm={6}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <ChildFriendlyOutlinedIcon className="iconcheckcircle" style={{ fontSize: '100px' }} />
                <h3>Guardería</h3>
                <h4>Horario de 7:00 am. a 7:00 pm. </h4>
            </Paper>
        </Grid>
        <Grid item md={3} sm={6}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <LocalLibraryIcon className="iconcheckcircle" style={{ fontSize: '100px' }} />
                <h3>Kinder</h3>
                <h4>Contamos con un metodo de enseñanza inigualable</h4>
            </Paper>
        </Grid>
        <Grid item md={3} sm={6}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <PeopleOutlinedIcon className="iconcheckcircle" style={{ fontSize: '100px' }} />
                <h3>Club de Tareas</h3>
                <h4>Ayudamos a tus hij@s en el proceso de realizacion de sus tareas</h4>
            </Paper>
        </Grid>
        <Grid item md={3} sm={6}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <FaceOutlinedIcon className="iconcheckcircle" style={{ fontSize: '100px' }} />
                <h3>Estancia Vespertina</h3>
                <h4>Si tus hij@s acuden a otra institución educativa, y necesitas ayuda vespertina, nosotros podemos ayudarte</h4>
            </Paper>
        </Grid>
        <Grid item md={3} sm={6}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <WidgetsOutlinedIcon className="iconcheckcircle" style={{ fontSize: '100px' }} />
                <h3>Estimulación Temprana</h3>
                <h4>Constante motivación de aprendizaje desde que tus hij@s ingresan en nuestra institución</h4>
            </Paper>
        </Grid>
        <Grid item md={3} sm={6}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <BeachAccessOutlinedIcon className="iconcheckcircle" style={{ fontSize: '100px' }} />
                <h3>Talleres</h3>
                <h4>Talleres divertidos para la formación integral de nuestr@s alumn@s</h4>
            </Paper>
        </Grid>
        <Grid item md={3} sm={6}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <LaptopChromebookOutlinedIcon className="iconcheckcircle" style={{ fontSize: '100px' }} />
                <h3>Computación</h3>
                <h4>Todos nuestros alum@s pueden acceder al curso de computación</h4>
            </Paper>
        </Grid>
        <Grid item md={3} sm={6}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <DeckOutlinedIcon className="iconcheckcircle" style={{ fontSize: '100px' }} />
                <h3>Comedor</h3>
                <h4>Servicio de comedor con alimentos balanceados</h4>
            </Paper>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <h1>
                ¡Precios Muy Accesibles!
            </h1>
            <hr />
            <h2>
                El costo de nuestros servicios varia dependiendo, de las necesidades de cada pequeñ@, contactanos con gusto, te ofrecemos la informacion correspondiente.
                <br />
                <br />
                Solicita la lista de precios vigente
            </h2>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <Button onClick={() => { whatsappsend() }} variant="outlined" startIcon={<WhatsAppIcon />}>
                Enviar Whatsapp
            </Button>
            <br />
            <br />
            <Button onClick={() => { callphone() }} variant="outlined" startIcon={<PhoneInTalkIcon />}>
                LLamar 5552074043
            </Button>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <h1>
                Nos encontramos muy cerca de ti
            </h1>
            <hr />
            <h2>
                Colima #390, Colonia Roma Norte, C.P. 06700, Del. Cuauhtemoc, CDMX
            </h2>
            <img src={escuela} alt="" width="360" />
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <h1 style={{ textAlign: 'center' }}>
                Testimonios
            </h1>
        </Grid>
        <Grid item md={6} style={{ textAlign: 'center' }}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <h4>
                    "Mi trabajo me demanda visitar a personal medico en CDMX, necesitaba un lugar seguro y confiable donde me ayudaran a cuidar a mis hij@s, en Roma Kids encontre un lugar confortable, seguro y de mucho aprendizaje para mi hijo, estoy muy tranquilo con su educación"
                </h4>
                <Avatar imagen={testimonio1} className="avatar" style={{ fontSize: '100px', textAlign: 'center' }} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Rating />
                </div>
                <hr />
                <h3>
                    Antonio Vargas
                    <br />
                    Ventas
                </h3>
            </Paper>
        </Grid>
        <Grid item md={6} style={{ textAlign: 'center' }}>
            <Paper elevation={3} style={{ textAlign: 'center' }}>
                <h4>
                    "Trabajo por la zona de reforma, visite varias guarderias de la zona, pero la verdad es que en Roma Kids, me siento super contenta por el servicio especializado que recibe mi hijo, muy recomendable ya que mi pequeño se ha vuelto independiente y seguro"
                </h4>
                <Avatar imagen={testimonio2} className="avatar" style={{ fontSize: '100px', textAlign: 'center' }} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Rating />
                </div>
                <hr />
                <h3>
                    Doris Correa
                    <br />
                    Asistente
                </h3>
            </Paper>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <Button onClick={() => { whatsappsend() }} variant="outlined" startIcon={<WhatsAppIcon />}>
                Enviar Whatsapp
            </Button>
            <br />
            <br />
            <Button onClick={() => { callphone() }} variant="outlined" startIcon={<PhoneInTalkIcon />}>
                LLamar 5552074043
            </Button>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <h1>
                Enseñamos con valores
            </h1>
            <hr />
            <h2>
                Respeto
            </h2>
            <h2>
                Amor
            </h2>
            <h2>
                Amistad
            </h2>
            <h2>
                Responsabilidad
            </h2>
            <h2>
                Compromiso
            </h2>
            <h2>
                Honestidad
            </h2>
            <h2>
                Equidad
            </h2>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <h1>
                ¡Agenda visita para conocer nuestras instalaciones!
            </h1>
            <hr />
            <h2>
                Envianos whatsapp o llamanos si tienes dudas sobre nuestro servicio y precios
            </h2>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <h1>
                +1000 Alumnos egresados de Roma Kids
            </h1>
            <hr />
            <h2>
                Nuestros alumnos egresados, llegan a otras instituciones educativas con bases sólidas en conocimiento, valores y aptitud de servicio, por lo que siempre sobresalen.
            </h2>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <Button onClick={() => { whatsappsend() }} variant="outlined" startIcon={<WhatsAppIcon />}>
                Enviar Whatsapp
            </Button>
            <br />
            <br />
            <Button onClick={() => { callphone() }} variant="outlined" startIcon={<PhoneInTalkIcon />}>
                LLamar 5552074043
            </Button>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center' }}>
            <h3>Roma Kids@2010 Todos los derechos Reservados</h3>
        </Grid>
    </Grid>
</Box >

function Landing() {
    return (
        <>
            <ScrollPage children={child} llamar={llamar} />
        </>
    )
}

export default Landing